<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.789706 4.1737L0.969658 4.8225L1.61173 4.61983L3.19568 4.11983C3.53802 4.01176 3.72793 3.64665 3.61987 3.30431C3.53234 3.02702 3.27615 2.84973 3.00003 2.8498C3.78083 2.10606 4.83747 1.64998 6.00002 1.64998C7.78278 1.64998 9.31649 2.72252 9.98834 4.26022C10.1321 4.58917 10.5153 4.73933 10.8442 4.5956C11.1732 4.45188 11.3233 4.06869 11.1796 3.73973C10.3083 1.74562 8.31789 0.349976 6.00002 0.349976C4.24763 0.349976 2.6824 1.1477 1.64646 2.39867L1.62637 2.32625C1.53043 1.98032 1.17222 1.77768 0.826292 1.87362C0.480367 1.96957 0.277718 2.32778 0.373664 2.6737L0.789706 4.1737ZM11.0304 7.17745L11.2104 7.82625L11.6264 9.32625C11.6476 9.4026 11.6542 9.47955 11.648 9.55414C11.6436 9.60629 11.6329 9.65729 11.6166 9.70613C11.5502 9.90398 11.39 10.0664 11.1738 10.1264C10.8278 10.2223 10.4696 10.0197 10.3737 9.67376L10.3536 9.60134C9.31765 10.8523 7.75241 11.65 6.00003 11.65C3.68216 11.65 1.69171 10.2544 0.820441 8.26028C0.763451 8.12984 0.752667 7.99088 0.780914 7.8617C0.823891 7.66507 0.957301 7.4911 1.15584 7.40435C1.4848 7.26062 1.86798 7.41078 2.01171 7.73974C2.68356 9.27744 4.21727 10.35 6.00004 10.35C7.16255 10.35 8.21917 9.89392 8.99996 9.15021C8.72386 9.15026 8.4677 8.97298 8.38017 8.6957C8.34492 8.58403 8.34138 8.46994 8.36449 8.36313C8.41221 8.14249 8.57369 7.95295 8.80437 7.88013L10.3883 7.38013L11.0304 7.17745Z"
      fill="currentColor"
    />
  </svg>
</template>
