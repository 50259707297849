

import __layout_0 from '/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/layouts/default.vue'
export const layouts = {
'AppPreloader': () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/layouts/AppPreloader.vue'),
'auth': () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/layouts/auth.vue'),
'default': __layout_0,
'footer/components/AppFooter': () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/layouts/footer/components/AppFooter.vue'),
'logo/components/AppLogo': () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/layouts/logo/components/AppLogo.vue'),
'navbar/components/AppNavbar': () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/layouts/navbar/components/AppNavbar.vue'),
'navbar/components/AppNavbarButton': () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/layouts/navbar/components/AppNavbarButton.vue'),
'navbar/components/AppNavbarMenu': () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/layouts/navbar/components/AppNavbarMenu.vue'),
'navbar/components/AppNavbarSearch': () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/layouts/navbar/components/AppNavbarSearch.vue'),
'header/components/AppHeader': () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/layouts/header/components/AppHeader.vue'),
'header/components/AppHeaderAccount': () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/layouts/header/components/AppHeaderAccount.vue'),
'header/components/AppHeaderCompany': () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/layouts/header/components/AppHeaderCompany.vue'),
'header/components/AppHeaderCurrency': () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/layouts/header/components/AppHeaderCurrency.vue'),
'header/components/AppHeaderLayout': () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/layouts/header/components/AppHeaderLayout.vue'),
'header/components/AppHeaderLayoutLabel': () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/layouts/header/components/AppHeaderLayoutLabel.vue'),
'header/components/AppHeaderManager': () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/layouts/header/components/AppHeaderManager.vue'),
'header/components/AppHeaderManagerInfo': () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/layouts/header/components/AppHeaderManagerInfo.vue'),
'header/components/AppHeaderMenu': () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/layouts/header/components/AppHeaderMenu.vue'),
'header/components/AppHeaderSupplier': () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/layouts/header/components/AppHeaderSupplier.vue'),
'sidebar/components/AppSidebar': () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/layouts/sidebar/components/AppSidebar.vue'),
'sidebar/components/AppSidebarBadge': () => import('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/B2B/B2B_Deploy_Prod/src/app/layouts/sidebar/components/AppSidebarBadge.vue'),
}
export const createGetRoutes = (router, withLayout = false) => {
  const routes = router.getRoutes()
  if (withLayout) {
      return routes
  }
  return () => routes.filter(route => !route.meta.isLayout)
}

export function setupLayouts(routes) {
  function deepSetupLayout(routes, top = true) {
    return routes.map(route => {
      if (route.children?.length > 0) {
        route.children = deepSetupLayout(route.children, false)
      }
      
      if (top) {
        // unplugin-vue-router adds a top-level route to the routing group, which we should skip.
        const skipLayout = !route.component && route.children?.find(r => (r.path === '' || r.path === '/') && r.meta?.isLayout)  

        if (skipLayout) {
          return route
        }

        if (route.meta?.layout !== false) {
          return { 
            path: route.path,
            component: layouts[route.meta?.layout || 'default'],
            children: route.path === '/' ? [route] : [{...route, path: ''}],
            meta: {
              isLayout: true
            }
          }
        }
      }

      if (route.meta?.layout) {
        return { 
          path: route.path,
          component: layouts[route.meta?.layout],
          children: [ {...route, path: ''} ],
          meta: {
            isLayout: true
          }
        }
      }

      return route
    })
  }

    return deepSetupLayout(routes)

}
