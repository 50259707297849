import { ref, computed, type ComputedRef } from 'vue';
import { defineStore } from 'pinia';
import { type Product } from '~/domains/product';
import { type BasketLine } from '../..';
import cloneDeep from 'lodash-es/cloneDeep';

export default defineStore('basketSelected', () => {
  const selectedProductItems = ref<BasketLine[]>([]);
  const selectedLicenceItems = ref<BasketLine[]>([]);

  const selectedSkus: ComputedRef<string[]> = computed(() =>
    selectedProductItems.value.map((selected: Product) => selected.sku),
  );

  const setSelectedProducts = (products: Product[]): void => {
    selectedProductItems.value = products;
  };

  const setSelectedLicence = (products: Product[]): void => {
    selectedLicenceItems.value = products;
  };

  const deleteFromSelectedLicence = (licences: Product[]): void => {
    const cloneSelectedItems = cloneDeep(selectedLicenceItems.value);

    licences.forEach((licence) => {
      const item = selectedLicenceItems.value.find(
        (item: Product) => item.sku === licence.sku,
      );
      const index = selectedLicenceItems.value.indexOf(item);
      if (index < 0) return;

      cloneSelectedItems.splice(index, 1);
    });

    selectedLicenceItems.value = cloneSelectedItems;
  };

  const deleteFromSelectedProducts = (products: Product[]): void => {
    const cloneSelectedItems = cloneDeep(selectedProductItems.value);

    products.forEach((product) => {
      const item = selectedProductItems.value.find(
        (item: Product) => item.sku === product.sku,
      );
      const index = selectedProductItems.value.indexOf(item);
      if (index < 0) return;

      cloneSelectedItems.splice(index, 1);
    });

    selectedProductItems.value = cloneSelectedItems;
  };

  const deleteFromSelected = (
    products: Product[],
    licenceTab: boolean,
  ): void => {
    if (licenceTab) {
      deleteFromSelectedLicence(products);
    } else {
      deleteFromSelectedProducts(products);
    }
  };

  const clearSelectedProducts = (): void => {
    selectedProductItems.value = [];
  };

  const clearSelectedLicence = (): void => {
    selectedLicenceItems.value = [];
  };

  const clearAllSelected = (): void => {
    selectedProductItems.value = [];
    selectedLicenceItems.value = [];
  };

  return {
    selectedProductItems,
    selectedLicenceItems,
    selectedSkus,
    setSelectedProducts,
    deleteFromSelected,
    setSelectedLicence,
    clearSelected: clearSelectedProducts,
    clearSelectedLicence,
    clearAllSelected,
  };
});
