import {
  userRepository,
  getUserInfoUseCase,
  type UserInfo,
  useUserStore,
} from '~/domains/user';

const useGetUserInfoAdapter = (): (() => Promise<UserInfo | undefined>) => {
  const getUserInfo = async (): Promise<UserInfo | undefined> => {
    const { setUser } = useUserStore();

    return await getUserInfoUseCase({
      userRepository,
      storage: {
        setUser,
      },
    });
  };

  return getUserInfo;
};

export default useGetUserInfoAdapter;
