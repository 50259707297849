<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.61248 4.37286C10.3195 3.9015 11.1503 3.64998 12 3.64998H12C13.8501 3.64986 15.35 5.14963 15.35 6.99973V8.35007H6.99985C5.5363 8.35007 4.34985 9.53652 4.34985 11.0001V19.0001C4.34985 20.4636 5.5363 21.6501 6.99985 21.6501H16.9999C18.4634 21.6501 19.6499 20.4636 19.6499 19.0001V11.0001C19.6499 9.53652 18.4634 8.35007 16.9999 8.35007H16.65V6.99973C16.65 4.43164 14.5681 2.34984 12 2.34998V3.64998L12 2.34998C10.8936 2.34998 9.81194 2.67748 8.89137 3.29119L8.63944 3.45914L9.36056 4.54081L9.61248 4.37286ZM6.99985 9.65007C6.25427 9.65007 5.64985 10.2545 5.64985 11.0001V19.0001C5.64985 19.7457 6.25427 20.3501 6.99985 20.3501H16.9999C17.7454 20.3501 18.3499 19.7457 18.3499 19.0001V11.0001C18.3499 10.2545 17.7454 9.65007 16.9999 9.65007H6.99985ZM14 15.0001C14 16.1046 13.1046 17.0001 12 17.0001C10.8954 17.0001 10 16.1046 10 15.0001C10 13.8955 10.8954 13.0001 12 13.0001C13.1046 13.0001 14 13.8955 14 15.0001Z"
      fill="currentColor"
    />
  </svg>
</template>
