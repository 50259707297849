import { type UserPreferencesTableState } from '~/domains/user';

export default function userPreferencesTableStateSerializer(
  tableState?: string,
): any {
  if (tableState !== undefined) {
    const parsedTableState = JSON.parse(
      tableState,
    ) as UserPreferencesTableState[];

    return parsedTableState?.map((column) =>
      column.colId === 'quantity.ordered' ||
      column.colId === 'actions' ||
      column.colId === '0'
        ? { ...column, pinned: 'right' }
        : column,
    );
  }

  return null;
}
