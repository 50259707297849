<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.5001 4.6501C8.3068 4.6501 8.1501 4.8068 8.1501 5.0001V6.8501H15.8501V5.0001C15.8501 4.8068 15.6934 4.6501 15.5001 4.6501H8.5001ZM17.1501 6.8501V5.0001C17.1501 4.08883 16.4114 3.3501 15.5001 3.3501H8.5001C7.58883 3.3501 6.8501 4.08883 6.8501 5.0001V6.8501H5.5001H4.0001C3.64111 6.8501 3.3501 7.14111 3.3501 7.5001C3.3501 7.85908 3.64111 8.1501 4.0001 8.1501H4.92079L6.25222 19.6892C6.34829 20.5218 7.05325 21.1501 7.89135 21.1501H16.1088C16.9469 21.1501 17.6519 20.5218 17.748 19.6892L19.0794 8.1501H20.0001C20.3591 8.1501 20.6501 7.85908 20.6501 7.5001C20.6501 7.14111 20.3591 6.8501 20.0001 6.8501H18.5001H17.1501ZM17.7708 8.1501H16.5001H7.5001H6.22941L7.54365 19.5402C7.56403 19.7168 7.71357 19.8501 7.89135 19.8501H16.1088C16.2866 19.8501 16.4362 19.7168 16.4565 19.5402L17.7708 8.1501Z"
      fill="currentColor"
    />
  </svg>
</template>
