import { type ProductActionResponse } from '../types';
import { dateSerializer } from '~/domains/core';

export default function productActionDateTillSerializer(
  data: ProductActionResponse,
): Date | undefined {
  return data.icon_type !== 'COMN' && data.icon_type !== 'FRAR'
    ? dateSerializer(data.date_till)
    : undefined;
}
