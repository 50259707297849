<template>
  <div class="app-header-menu">
    <template v-if="showCredit">
      <AppNavbarButton
        v-for="item in menuItems"
        :key="item.id"
        :label="item.label"
        :text="creditText"
        :prepend-icon="item.icon"
        :variant="item.variant"
        :color="overdueIsMoreZero ? 'error' : 'primary'"
        :link="item.link"
      />
    </template>
    <CompareButton />
    <BasketButton v-if="showOrdering" />
  </div>
</template>

<script setup lang="ts">
import { BasketButton } from '~/domains/basket';
import { CompareButton } from '~/domains/compare';
import AppNavbarButton from './AppNavbarButton.vue';
import { computed } from 'vue';
import useUserStore from '~/domains/user/user.store';
import { useAvailableCredit } from '~/domains/user/composables/useAvailableCredit';
import { storeToRefs } from 'pinia';
import { usePermissions } from '~/infrastructure';

const { user } = storeToRefs(useUserStore());
const { availableCreditContent, overdueIsMoreZero, isCreditLimit } =
  useAvailableCredit();
const { showOrdering, showAccount } = usePermissions();

const currentCurrency = computed(() => user.value?.selected.currency);
const showCredit = computed(
  () => isCreditLimit.value && showAccount.value && showOrdering.value,
);
const creditText = computed(
  () => `${availableCreditContent.value} ${currentCurrency.value}`,
);

const menuItems = computed(() => [
  {
    id: 1,
    label: overdueIsMoreZero.value
      ? 'header.info.overdue.label'
      : 'header.info.availableCredit.label',
    icon: '$coins',
    variant: 'text',
    link: '/account',
  },
]);
</script>

<style>
.app-header-menu {
  display: flex;
  column-gap: 16px;
  margin-left: auto;
}

.app-header-menu-button {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
